import type { ElementType } from "react";
import { cn } from "~/util/cn";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  as?: ElementType;
  size?: "sm" | "md";
  look?: "default" | "gray" | "funky";
  hover?: boolean;
  active?: boolean;
}

const sizes = {
  sm: "p-2 rounded-md",
  md: "p-6 rounded-xl",
};
const looks = (hover: boolean) => ({
  default: cn(
    "bg-none",
    hover && "hover:from-gray-50 hover:to-gray-100 hover:bg-gradient-to-br",
  ),
  gray: cn(
    "bg-gradient-to-br from-gray-100 to-gray-50",
    hover && "hover:from-gray-100 hover:to-gray-200",
  ),
  funky: cn("bg-white text-blu-500 border-yello-500 rounded-[1em] border-4 "),
});

export const Card: React.FC<Props> = ({
  as: tagname = "div",
  size = "md",
  look = "default",
  hover = true,
  active = false,
  className,
  ...props
}) => {
  const Tag = tagname;
  return (
    <Tag
      {...props}
      className={cn(
        "transition-colors",
        sizes[size],
        looks(hover)[look],
        active && "bg-gradient-to-br from-gray-200 to-gray-300",
        className,
      )}
    >
      {props.children}
    </Tag>
  );
};
export default Card;
